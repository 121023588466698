<template>
  <div class="rightBlock" :style="{ marginBottom: marginBotValue + 'px' }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ["margBot", "margBotTab", "margBotMob"],
  computed: {
    marginBotValue() {
      if (window.innerWidth > 1200) {
        return this.margBot;
      }
      if (window.innerWidth < 1200 && window.innerWidth > 767) {
        return this.margBotTab;
      }
      if (window.innerWidth < 767) {
        return this.margBotMob;
      }
    },
  },
};
</script>

<style scoped lang="sass">
.rightBlock
  width: 66%
  margin-left: auto
  position: relative
  display: flex
  flex-direction: column

@media screen and (max-width: 1200px)
  .rightBlock
    margin-left: 0
    width: 100%

@media screen and (max-width: 767px)
  .rightBlock
    margin-left: 0
    width: 100%
</style>